$width: 100vw / 1520px;

.rtl,
.rtl h5 {
    direction: rtl;
    text-align: right;
}

.md-form {
    height: 60px !important;
    border: 1px solid #ccc;
    border-radius: 1rem;
}

.rtl .timepicker {
    height: 100% !important;
    padding: 0 !important;
    margin: 0 !important;
    color: #3e4858 !important;
    font-family: "IRANSansMobile(FaNum)", DiodrumArabic;
    // border: 1px solid #CCC !important;
    border-radius: inherit !important;
    width: 100%;
    background-color: #f3f5f9 !important;
    display: block;
}

.ltr .timepicker {
    height: 100% !important;
    padding: 0 !important;
    margin: 0 !important;
    color: #3e4858 !important;
    font-family: DiodrumArabic;
    // border: 1px solid #CCC !important;
    border-radius: inherit !important;
    width: 100%;
    background-color: #f3f5f9 !important;
    display: block;
}

.rtl .timepicker {
    background-image: url("../../assets/img/Time.png");
    background-repeat: no-repeat;
    background-position: 5% 50%;
    background-size: 20px 24px;
    background-color: #f3f5f9;
}

.ltr .timepicker {
    background-image: url("../../assets/img/Time.png");
    background-repeat: no-repeat;
    background-position: 90% 50%;
    background-size: 20px 24px;
    background-color: #f3f5f9;
}

.rtl .DatePicker input {
    background-image: url("../../assets/img/Calendar.png");
    background-repeat: no-repeat;
    background-position: 10% 50%;
    z-index: 100;
    background-size: 20px 24px;
    border: 1px solid #ccc;
}

.ltr .DatePicker input {
    background-image: url("../../assets/img/Calendar.png");
    background-repeat: no-repeat;
    background-position: 90% 50%;
    z-index: 100;
    background-size: 20px 24px;
    border: 1px solid #ccc;
}

.DatePicker input {
    height: 100%;
}

.rtl {

    input[type="text"]::placeholder,
    input[type="number"]::placeholder,
    input[type="password"]::placeholder,
    input[type="time"]::placeholder,
    .DatePicker input::placeholder,
    input::placeholder {
        color: #3d4856 !important;
        font-family: "IRANSansMobile(FaNum)", DiodrumArabic !important;
    }
}

.ltr {

    input[type="text"]::placeholder,
    input[type="number"]::placeholder,
    input[type="password"]::placeholder,
    input[type="time"]::placeholder,
    .DatePicker input::placeholder,
    input::placeholder {
        color: #3d4856 !important;
        font-family: DiodrumArabic;

    }
}

input[type="text"]:not(.timepicker),
input[type="number"],
input[type="password"],
input[type="time"],
input[type="email"],
select {
    padding: 0.5rem !important;
    color: #3e4858 !important;
    font-family: DiodrumArabic;
    border: none !important;
    /* <-- This thing here */
    border: solid 1px #ccc !important;
    border-radius: 1rem;
    width: 100%;
    background-color: #f3f5f9;
    display: block;
}

.rtl {

    input[type="text"]:not(.timepicker),
    input[type="number"],
    input[type="password"],
    input[type="time"],
    input[type="email"],
    select {
        padding: 0.5rem !important;
        color: #3e4858 !important;
        font-family: "IRANSansMobile(FaNum)", DiodrumArabic;
        border: none !important;
        /* <-- This thing here */
        border: solid 1px #ccc !important;
        border-radius: 1rem;
        width: 100%;
        background-color: #f3f5f9;
        display: block;
    }
}

.rtl input {
    text-align: right;
}

.ltr input {
    text-align: left;
}

input[type="text"]:focus,
input[type="number"]:focus,
input[type="password"]:focus,
input:focus,
select {
    background-color: white;
}

.input-group>.custom-select:not(:first-child),
.input-group>.form-control:not(:first-child) {
    border-radius: 0.8rem;
}

.select-dropdown,
.select-dropdown {
    border: none !important;
    background-color: white !important;
    color: #041526;
    border-radius: 0.8rem !important;
    padding: 0 0.3rem !important;
    height: 100%;
}

.rtl select {
    -webkit-appearance: none;
    appearance: none;
    -moz-appearance: none;
    padding: 0px 0.6rem !important;
    background: #f3f5f9 url("../../assets/img/caret.png") no-repeat 20px;
    border: none;
    /* <-- This thing here */
    border: solid 1px #ccc !important;
    border-radius: 1rem;
    height: 3rem;
    width: 100%;
    text-align: right !important;
    color: #3c4858 !important;
    line-height: 3rem;
    font-weight: 900;
}

.rtl select option {
    font-family: DiodrumArabic;
}

.ltr select {
    direction: ltr !important;
    -webkit-appearance: none;
    appearance: none;
    -moz-appearance: none;
    padding: 1rem !important;
    background: #f3f5f9 url("../../assets/img/caret.png") no-repeat calc(100% - 20px);
    border: 1px solid #ccc;
    border-radius: 1rem !important;
    height: 60px;
    width: 100%;
    text-align: left !important;
    color: #3c4858 !important;
    font-weight: 900;
}

select:required:invalid {
    color: #666;
}

option[value=""][disabled] {
    display: none;
}

option {
    color: #000;
}

.do-search {
    color: #ffc212;
    background-color: #1458e9;
    width: 3rem;
    height: 3rem;
    border: none;
    border-radius: 1rem;
    direction: rtl;
}

.img-container {
    border-radius: 1rem;
    // background-color: #f3f5f9;
}

.img-container .img {
    display: block;
    margin: 0 auto;
    width: 100%;
    height: 100%;
    border-radius: 1rem;
    aspect-ratio: 16/9;

}

input[type="file"] {
    height: 10rem;
    border-radius: 1rem;
    background-color: #f3f5f9;
}

.responsive-calendar {
    /* by setting font-size, all the elements will correspond */
    font-size: 9px !important;
    /* default to 10px */
    height: 3rem;
}

.DatePicker {
    width: 100%;
    border-radius: 1rem;
    background-color: #f3f5f9 !important;
    height: 60px;
    display: flex;
    align-items: center;
}

.DatePicker input {
    width: 100%;
    padding: 0.5rem !important;
    color: #9ca5b6 !important;
    font-family: DiodrumArabic;
    background-color: #f3f5f9 !important;
    border-radius: 1rem;

    font-size: 12pt;
}

.rtl {
    .DatePicker input {
        font-family: "IRANSansMobile(FaNum)", DiodrumArabic;
    }
}

.DatePicker__calendarContainer {
    z-index: 1000;
    position: absolute !important;
}

@media (max-width: 1500px) {
    .responsive-calendar {
        font-size: 8px !important;
    }
}

@media (max-width: 1200px) {
    .responsive-calendar {
        font-size: 7px !important;
    }
}

@media (max-width: 768px) {
    .responsive-calendar {
        font-size: 6px !important;
    }
}

/* Large screens */
@media (min-width: 2500px) {
    .responsive-calendar {
        font-size: 12px !important;
    }
}

.modal-title {
    margin: 0 auto;
    display: block;
}

.multiselect-container input {
    height: 58px;
}

.search-form {
    .text-search {
        width: 160px * $width;
        height: 60px;
        margin-left: 2px * $width;
    }

    .select-dropdown {
        width: 160px * $width;
        margin-left: 2px * $width;
        height: 60px;

        .drop-down-container {
            padding: 0;
            height: 100%;

            label {
                height: 100%;
            }
        }
    }
}

:disabled {
    opacity: 0.5;
    cursor: progress;
}

.delete-video {
    cursor: pointer;
}

.card-video {
    cursor: pointer;
}