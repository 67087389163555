$width: 100vw / 1520px;
@media (min-width: 1000px) {
  .do-search-report {
    width: 49%;
    color: #ffc212;
    background-color: #1458e9;
    height: 3rem;
    border: none;
    border-radius: 1rem;
    direction: ltr;
    right: 0;
    float: right;
    padding: 1rem;
  }
}
@media (max-width: 1000px) {
  .do-search-report {
    width: 100%;
    color: #ffc212;
    background-color: #1458e9;
    height: 3rem;
    border: none;
    border-radius: 1rem;
    direction: ltr;
    right: 0;
    float: right;
    padding: 1rem;
  }
}
.duration-picker {
  width: auto;
  color: #ffc212;
  background-color: none;
  height: 3rem;
  border: none;
  border-radius: 1rem;
  direction: ltr;
  bottom: 0;
  right: 0;
  float: right;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.filter-row {
  .col,
  [class*="col"] {
    padding: 0 5px * $width 0 5px * $width !important;
    margin-bottom: 1rem;
  }
  .search-wrapper {
    overflow: hidden;
    white-space: nowrap;
  }
}
.rtl {
  .report-table {
    width: 1040px * $width;
    // table-layout: fixed;

    td,
    th {
      padding-top: 23px;
      padding-bottom: 22px;
      white-space: nowrap;
    }
    .table-row.active,
    .collapse-row.active {
      background-color: #ffbb3340 !important;
    }
    .collapse-row.show {
      display: table-row;
      td {
        // padding-right: 7%;
        border: none;
        table {
          td {
            padding: 0.5rem;
          }
        }
        span:not(.toggle-switch-inner):not(.toggle-switch-switch) {
          background-color: #1458e9;
          color: #e3e8ee;
          padding: 0.5rem;
          border-radius: 1rem;
          margin-left: 0.5rem;
          margin-right: 0.5rem;
        }
        // [class*="col"] {
        //   background-color: #e3e8ee !important;
        //   padding: 1rem 0.5rem 1rem 0.5rem;
        //   border: 1px solid #ccc;
        //   border-top: none;
        // }
        // [class*="col"]:first-child {
        //   border-bottom-right-radius: 0.5rem;
        // }
        // [class*="col"]:last-child {
        //   border-bottom-left-radius: 0.5rem;
        // }
      }
    }
    .collapse-row.hide {
      display: none;
    }
  }
}
.ltr {
  .report-table {
    width: 1040px * $width;
    // table-layout: fixed;

    td,
    th {
      padding-top: 23px;
      padding-bottom: 22px;
    }
    .table-row.active,
    .collapse-row.active {
      background-color: #ffbb3340 !important;
    }

    .collapse-row.show {
      display: table-row;
      td {
        // padding-right: 7%;
        border: none;
        table {
          td {
            padding: 0.5rem;
          }
        }
        span {
          background-color: #1458e9;
          color: #e3e8ee;
          padding: 0.5rem;
          border-radius: 1rem;
          margin-left: 0.5rem;
          margin-right: 0.5rem;
        }
        // [class*="col"] {
        //   background-color: #e3e8ee !important;
        //   padding: 1rem 0.5rem 1rem 0.5rem;
        //   border: 1px solid #ccc;
        //   border-top: none;
        // }
        // [class*="col"]:first-child {
        //   border-bottom-right-radius: 0.5rem;
        // }
        // [class*="col"]:last-child {
        //   border-bottom-left-radius: 0.5rem;
        // }
      }
    }
    .collapse-row.hide {
      display: none;
    }
  }
}
.react-tag-input {
  border: 1px solid #ccc !important;
  background-color: #f3f5f9 !important;
  border-radius: 1rem !important;
  height: 60px !important;
} /* Wrapper */
.react-tag-input__input {
  background-color: #f3f5f9 !important;
} /* Inner input */
.react-tag-input__tag {
} /* Tag wrapper */
.react-tag-input__tag__content {
} /* The text content within the tag */
.react-tag-input__tag__remove {
} /* The remove button & icon for a tag */
