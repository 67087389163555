
body {
  margin: 0;
  padding: 0;
  font-family: DiodrumArabic;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.font-diodrum{
  font-family: DiodrumArabic;
}
code {
  /* font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace; */
}
