$width: 100vw / 1520px;
@media (min-width: 1000px) {
    .do-search-report {
        width: 49%;
    }
}
@media (max-width: 1000px) {
    .do-search-report {
        width: 100%;
    }
}
.filter-container {
    width: 100%;
    form {
        transition: all 0.3s linear;
    }
    .col,
    [class*="col"] {
        padding: 0 5px * $width 0 5px * $width !important;
        margin-bottom: 1rem;
    }
    .search-wrapper {
        overflow: hidden;
        white-space: nowrap;
    }
    .do-search-report {
        color: #ffc212;
        background-color: #1458e9;
        height: 3rem;
        border: none;
        border-radius: 1rem;
        direction: ltr;
        right: 0;
        float: right;
        padding: 1rem;
    }
    .duration-picker {
        width: auto;
        color: #ffc212;
        background-color: none;
        height: 3rem;
        border: none;
        border-radius: 1rem;
        direction: ltr;
        bottom: 0;
        right: 0;
        float: right;
        font-family: DiodrumArabic;
    }
    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    button {
        font-family: DiodrumArabic;
    }
}
.rtl,
.ltr {
    .main-container {
        width: 100%;
    }
    .header-container {
        display: flex;
        justify-content: space-between;
        h5 {
            font-size: 30px;
            font-family: DiodrumArabicBold;
            margin-bottom: 44px;
            width: 10%;
        }
        .chart-option {
            float: left;
            width: 90%;
            display: flex;
            flex-direction: row;
            justify-content: end;
            select {
                height: 60px;
                width: 270px;
                display: inline-block;
                font-family: DiodrumArabicRegular;
                &:nth-child(2),
                &:nth-child(3) {
                    margin: 0 1rem;
                }
            }
        }
    }
    .body-container {
        display: flex;
        .chart-container {
            width: 60%;
        }
        .color-container {
            width: 340px * $width;
            ul {
                list-style: none;
                display: flex;
                flex-direction: column;
                justify-content: center;
                li {
                    font-family: DiodrumArabicRegular;
                    border-top: 1px #c0ccda solid;
                    padding: 20px 0 20px 0;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    span {
                        display: flex;
                        align-items: center;
                    }
                    i {
                        width: 26px;
                        height: 26px;
                        display: inline-block;
                        border-radius: 4px;
                        border: 6px solid white;
                        box-shadow: 0px 3px 6px #e5e9f2;
                        margin: 0 12px;
                    }
                }
                li:first-child {
                    border: none;
                }
            }
        }
    }
}
