.signal {
    padding: 2%;
    box-shadow: 0 0 4px #ccc;
    border-radius: 2rem;
    div {
        border-radius: 2rem;
        transition: all 0.3s;
        height: 0%;
        &.h-1 {
            height: 20%;
        }
        &.h-2 {
            height: 40%;
        }
        &.h-3 {
            height: 60%;
        }
        &.h-4 {
            height: 80%;
        }
        &.h-5 {
            height: 100%;
        }
    }
}
.channel {
    border-radius: 1rem;
    object-fit: cover;
    margin-right: 15px;
    box-shadow: 0 0 3px #949bb4;
    padding: 1rem;
    background-color: #f9fafd;
    &::before {
        content: "";
        position: absolute;
        background-color: #23be7e;
        width: 1rem;
        height: 1rem;
        border-radius: 50%;
        border: 2px solid var(--theme-bg-color);
        right: -.25rem;
        bottom: -.25rem;
        aspect-ratio: 1;
        animation: alert 1s infinite linear;
    }
}
@keyframes alert {
    0% {
        opacity: 1;
    }
    50% {
        opacity: .3;
    }

    100% {
        opacity: 1;
    }
}
