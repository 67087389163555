@font-face {
    font-family: "IRANSans";
    src: url("../assets/fonts/IRANSans.ttf");
    src: url("../assets/fonts/IRANSans_Bold.eot?#iefix") format("embedded-opentype"),
        url("../assets/fonts/IRANSans_Bold.woff") format("woff"),
        url("../assets/fonts/IRANSans_Bold.ttf") format("truetype"),
        url("../assets/fonts/IRANSans_Bold.svg#IRANSans_Bold") format("svg");
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
}
@font-face {
    font-family: "IRANSans";
    src: url("../assets/fonts/IRANSans.eot");
    src: url("../assets/fonts/IRANSans.eot?#iefix") format("embedded-opentype"),
        url("../assets/fonts/IRANSans.woff") format("woff"), url("../assets/fonts/IRANSans.ttf") format("truetype"),
        url("../assets/fonts/IRANSans.svg#MinionPro-Regular") format("svg");
    font-weight: normal;
    font-style: normal;
    font-stretch: condensed;
}

@font-face {
    font-family: "IRANSans_Bold";
    src: url("../assets/fonts/IRANSans_Bold.eot");
    src: url("../assets/fonts/IRANSans_Bold.eot?#iefix") format("embedded-opentype"),
        url("../assets/fonts/IRANSans_Bold.woff") format("woff"),
        url("../assets/fonts/IRANSans_Bold.ttf") format("truetype"),
        url("../assets/fonts/IRANSans_Bold.svg#IRANSans_Bold") format("svg");
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
}

@font-face {
    font-family: "IRANSans_Medium";
    src: url("../assets/fonts/IRANSans_Medium.eot");
    src: url("../assets/fonts/IRANSans_Medium.eot?#iefix") format("embedded-opentype"),
        url("../assets/fonts/IRANSans_Medium.woff") format("woff"),
        url("../assets/fonts/IRANSans_Medium.ttf") format("truetype"),
        url("../assets/fonts/IRANSans_Medium.svg#IRANSans_Medium") format("svg");
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
}

@font-face {
    font-family: "IRANSansMobile(FaNum)";
    src: url("../assets/fonts/IRANSansMobile(FaNum).eot");
    src: url("../assets/fonts/IRANSansMobile(FaNum).eot?#iefix") format("embedded-opentype"),
        url("../assets/fonts/IRANSansMobile(FaNum).woff") format("woff"),
        url("../assets/fonts/IRANSansMobile(FaNum).ttf") format("truetype"),
        url("../assets/fonts/IRANSansMobile(FaNum).svg#IRANSansMobile(FaNum)") format("svg");
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    unicode-range: U+30-39;
}

@font-face {
    font-family: "MyriadPro-Regular";
    src: url("../assets/fonts/MyriadPro-Regular.eot");
    src: url("../assets/fonts/MyriadPro-Regular.eot?#iefix") format("embedded-opentype"),
        url("../assets/fonts/MyriadPro-Regular.woff") format("woff"),
        url("../assets/fonts/MyriadPro-Regular.ttf") format("truetype"),
        url("../assets/fonts/MyriadPro-Regular.svg#MyriadPro-Regular") format("svg");
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
}

@font-face {
    font-family: "MyriadPro-Semibold";
    src: url("../assets/fonts/MyriadPro-Semibold.eot");
    src: url("../assets/fonts/MyriadPro-Semibold.eot?#iefix") format("embedded-opentype"),
        url("../assets/fonts/MyriadPro-Semibold.woff") format("woff"),
        url("../assets/fonts/MyriadPro-Semibold.ttf") format("truetype"),
        url("../assets/fonts/MyriadPro-Semibold.svg#MyriadPro-Semibold") format("svg");
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
}

@font-face {
    font-family: Anjoman;
    font-style: normal;
    font-weight: 100;
    src: url("../assets/fonts//eot/Anjoman-NoEn-Thin.eot");
    src: url("../assets/fonts//eot/Anjoman-NoEn-Thin.eot?#iefix") format("embedded-opentype"),
        /* IE6-8 */ url("../assets/fonts//woff2/Anjoman-NoEn-Thin.woff2") format("woff2"),
        /* FF39+,Chrome36+, Opera24+*/ url("../assets/fonts//woff/Anjoman-NoEn-Thin.woff") format("woff");
    /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
}

@font-face {
    font-family: Anjoman;
    font-style: normal;
    font-weight: 200;
    src: url("../assets/fonts//eot/Anjoman-NoEn-ExtraLight.eot");
    src: url("../assets/fonts//eot/Anjoman-NoEn-ExtraLight.eot?#iefix") format("embedded-opentype"),
        /* IE6-8 */ url("../assets/fonts//woff2/Anjoman-NoEn-ExtraLight.woff2") format("woff2"),
        /* FF39+,Chrome36+, Opera24+*/ url("../assets/fonts//woff/Anjoman-NoEn-ExtraLight.woff") format("woff");
    /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
}

@font-face {
    font-family: "AnjomanLight";
    font-style: normal;
    font-weight: 300;
    src: url("../assets/fonts//eot/Anjoman-NoEn-Light.eot");
    src: url("../assets/fonts//eot/Anjoman-NoEn-Light.eot?#iefix") format("embedded-opentype"),
        /* IE6-8 */ url("../assets/fonts//woff2/Anjoman-NoEn-Light.woff2") format("woff2"),
        /* FF39+,Chrome36+, Opera24+*/ url("../assets/fonts//woff/Anjoman-NoEn-Light.woff") format("woff");
    /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
}

@font-face {
    font-family: "AnjomanMedium";
    font-style: normal;
    font-weight: 500;
    src: url("../assets/fonts//eot/Anjoman-NoEn-Medium.eot");
    src: url("../assets/fonts//eot/Anjoman-NoEn-Medium.eot?#iefix") format("embedded-opentype"),
        /* IE6-8 */ url("../assets/fonts//woff2/Anjoman-NoEn-Medium.woff2") format("woff2"),
        /* FF39+,Chrome36+, Opera24+*/ url("../assets/fonts//woff/Anjoman-NoEn-Medium.woff") format("woff");
    /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
}

@font-face {
    font-family: "AnjomanSemiBold";
    font-style: normal;
    font-weight: 600;
    src: url("../assets/fonts//eot/Anjoman-NoEn-SemiBold.eot");
    src: url("../assets/fonts//eot/Anjoman-NoEn-SemiBold.eot?#iefix") format("embedded-opentype"),
        /* IE6-8 */ url("../assets/fonts//woff2/Anjoman-NoEn-SemiBold.woff2") format("woff2"),
        /* FF39+,Chrome36+, Opera24+*/ url("../assets/fonts//woff/Anjoman-NoEn-SemiBold.woff") format("woff");
    /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
}

@font-face {
    font-family: "AnjomanExtraBold";
    font-style: normal;
    font-weight: 750;
    src: url("../assets/fonts//eot/Anjoman-NoEn-ExtraBold.eot");
    src: url("../assets/fonts//eot/Anjoman-NoEn-ExtraBold.eot?#iefix") format("embedded-opentype"),
        /* IE6-8 */ url("../assets/fonts//woff2/Anjoman-NoEn-ExtraBold.woff2") format("woff2"),
        /* FF39+,Chrome36+, Opera24+*/ url("../assets/fonts//woff/Anjoman-NoEn-ExtraBold.woff") format("woff");
    /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
}

@font-face {
    font-family: "AnjomanUltraBold";
    font-style: normal;
    font-weight: 800;
    src: url("../assets/fonts//eot/Anjoman-NoEn-UltraBold.eot");
    src: url("../assets/fonts//eot/Anjoman-NoEn-UltraBold.eot?#iefix") format("embedded-opentype"),
        /* IE6-8 */ url("../assets/fonts//woff2/Anjoman-NoEn-UltraBold.woff2") format("woff2"),
        /* FF39+,Chrome36+, Opera24+*/ url("../assets/fonts//woff/Anjoman-NoEn-UltraBold.woff") format("woff");
    /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
}

@font-face {
    font-family: "AnjomanBlack";
    font-style: normal;
    font-weight: 850;
    src: url("../assets/fonts//eot/Anjoman-NoEn-Black.eot");
    src: url("../assets/fonts//eot/Anjoman-NoEn-Black.eot?#iefix") format("embedded-opentype"),
        /* IE6-8 */ url("../assets/fonts//woff2/Anjoman-NoEn-Black.woff2") format("woff2"),
        /* FF39+,Chrome36+, Opera24+*/ url("../assets/fonts//woff/Anjoman-NoEn-Black.woff") format("woff");
    /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
}

@font-face {
    font-family: "AnjomanHeavy";
    font-style: normal;
    font-weight: 900;
    src: url("../assets/fonts//eot/Anjoman-NoEn-Heavy.eot");
    src: url("../assets/fonts//eot/Anjoman-NoEn-Heavy.eot?#iefix") format("embedded-opentype"),
        /* IE6-8 */ url("../assets/fonts//woff2/Anjoman-NoEn-Heavy.woff2") format("woff2"),
        /* FF39+,Chrome36+, Opera24+*/ url("../assets/fonts//woff/Anjoman-NoEn-Heavy.woff") format("woff");
    /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
}

@font-face {
    font-family: "AnjomanSuperHeavy";
    font-style: normal;
    font-weight: 1000;
    src: url("../assets/fonts//eot/Anjoman-NoEn-SuperHeavy.eot");
    src: url("../assets/fonts//eot/Anjoman-NoEn-SuperHeavy.eot?#iefix") format("embedded-opentype"),
        /* IE6-8 */ url("../assets/fonts//woff2/Anjoman-NoEn-SuperHeavy.woff2") format("woff2"),
        /* FF39+,Chrome36+, Opera24+*/ url("../assets/fonts//woff/Anjoman-NoEn-SuperHeavy.woff") format("woff");
    /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
}

@font-face {
    font-family: "AnjomanBold";
    font-style: normal;
    font-weight: bold;
    src: url("../assets/fonts//eot/Anjoman-NoEn-Bold.eot");
    src: url("../assets/fonts//eot/Anjoman-NoEn-Bold.eot?#iefix") format("embedded-opentype"),
        /* IE6-8 */ url("../assets/fonts//woff2/Anjoman-NoEn-Bold.woff2") format("woff2"),
        /* FF39+,Chrome36+, Opera24+*/ url("../assets/fonts//woff/Anjoman-NoEn-Bold.woff") format("woff");
    /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
}

@font-face {
    font-family: "AnjomanRegular";
    font-style: normal;
    font-weight: normal;
    src: url("../assets/fonts//eot/Anjoman-NoEn-Regular.eot");
    src: url("../assets/fonts//eot/Anjoman-NoEn-Regular.eot?#iefix") format("embedded-opentype"),
        /* IE6-8 */ url("../assets/fonts//woff2/Anjoman-NoEn-Regular.woff2") format("woff2"),
        /* FF39+,Chrome36+, Opera24+*/ url("../assets/fonts//woff/Anjoman-NoEn-Regular.woff") format("woff");
    /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
}
@font-face {
    font-family: "DiodrumArabic";
    font-style: normal;
    font-weight: normal;
    src: url("../assets/fonts/DiodrumArabic-Semibold.ttf");
}
@font-face {
    font-family: "DiodrumArabicBold";
    font-style: normal;
    font-weight: normal;
    src: url("../assets/fonts/DiodrumArabic-Bold.ttf");
}
@font-face {
    font-family: "DiodrumArabicRegular";
    font-style: normal;
    font-weight: normal;
    src: url("../assets/fonts/DiodrumArabic-Regular.ttf");
}