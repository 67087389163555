.tabs-primary {
    background-color: #2957df !important;
}
.classic-tabs .nav {
    border-radius: 12px 12px 0 0;
}
.card {
    border: 1px solid #f9fafd;
    box-shadow: 0 0 0 1px white;
    border-radius: 12px;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    padding: 1rem;
}
