$width: 100vw / 1520px;
body :not(.drop-down-container) :focus {
}
.rtl .drop-down-container {
    position: relative;
    label:not(.disabled) {
        width: 100%;
        height: 60px;
        cursor: pointer;
        border-radius: 1rem;
        padding: 01rem;
        overflow: hidden;
        white-space: nowrap;
        display: flex;
        align-items: center;
        margin-bottom: 0;
        font-family: DiodrumArabic !important;
        border: 1px solid #ccc;
        color: #3c4858 !important;
        font-weight: 900;
        position: relative;
        background: #f3f5f9
            url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAGCAYAAAD68A/GAAAAAXNSR0IArs4c6QAAADhlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAAqACAAQAAAABAAAACqADAAQAAAABAAAABgAAAADdQHfOAAAAnklEQVQIHWOU1nYwYGBkmM/wnyHx6dUDFxiQAFiOiWE9wz+GQCZGRsYJQGzAyMSwHywBVQhig8QYGRgVwGoEFBwEuHkYDzAyMugzMPz/8P8fgyNILUgRkBT4/5/h4tcv/x0YoQYwSOs6LADqjgcphogBFTH8X/j08oEEEJ8ZIsjA8PnVgw284gqKQMUWQJM4kBXB1KDQIJNBGEUQyAEAhx4uhuoIJfsAAAAASUVORK5CYII=)
            no-repeat 20px !important;
        i {
            padding: 0.5rem;
            color: red;
            position: absolute;
            left: 40px * $width;
            cursor: pointer;
        }
    }
    label.disabled {
        width: 100%;
        height: 60px;
        cursor: not-allowed;
        border-radius: 1rem;
        padding: 01rem;
        overflow: hidden;
        white-space: nowrap;
        display: flex;
        align-items: center;
        margin-bottom: 0;
        font-family: DiodrumArabic !important;
        border: 1px solid #E5E5E6;
        color: #9FA3AB !important;
        font-weight: 900;
        position: relative;
        background: #F9FAFC
            url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAGCAYAAAD68A/GAAAAAXNSR0IArs4c6QAAADhlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAAqACAAQAAAABAAAACqADAAQAAAABAAAABgAAAADdQHfOAAAAnklEQVQIHWOU1nYwYGBkmM/wnyHx6dUDFxiQAFiOiWE9wz+GQCZGRsYJQGzAyMSwHywBVQhig8QYGRgVwGoEFBwEuHkYDzAyMugzMPz/8P8fgyNILUgRkBT4/5/h4tcv/x0YoQYwSOs6LADqjgcphogBFTH8X/j08oEEEJ8ZIsjA8PnVgw284gqKQMUWQJM4kBXB1KDQIJNBGEUQyAEAhx4uhuoIJfsAAAAASUVORK5CYII=)
            no-repeat 20px !important;
        i {
            display: none;
            padding: 0.5rem;
            color: red;
            position: absolute;
            left: 40px * $width;
            cursor: pointer;
        }
    }
    .drop-down-list {
        list-style: none;
        width: 100%;
        padding: 0;
        position: absolute;
        background-color: white;
        z-index: 110;
        border: 1px solid #ccc;
        overflow: auto;
        max-height: 20rem;
        font-family: DiodrumArabic;
        li {
            width: 100%;
            padding: .5rem 1rem;
            cursor: pointer;
            color: #3c4858;
            border: 1px dotted #CCC;
        }
        li:nth-child(n + 2) {
            line-height: 2.5rem;
        }
        li:nth-child(n + 2):hover {
            background-color: #779ae6;
            color: white;
        }
        li.choosed {
            background-color: #1458e9;
            color: white;
        }
        li:first-child {
            display: flex;
            align-items: center;
            input {
                width: 100%;
                border: 1px solid #ccc;
                height: 2.5rem;
                border-radius: 0.3rem;
            }
            .insert {
                width: 2.5rem;
                height: 2.5rem;
                background-color: rgb(20, 88, 233);
                border: none;
                margin-right: 0.5rem !important;
                padding: 0.5rem;
                border-radius: 1rem;
                img {
                    width: 100%;
                    margin: 0;
                }
            }
        }
    }
}

.ltr .drop-down-container {
    position: relative;
    label:not(.disabled) {
        width: 100%;
        height: 60px;
        cursor: pointer;
        border-radius: 1rem;
        padding: 01rem;
        overflow: hidden;
        white-space: nowrap;
        display: flex;
        align-items: center;
        margin-bottom: 0;
        font-family: DiodrumArabic !important;
        border: 1px solid #ccc;
        color: #3c4858 !important;
        font-weight: 900;
        position: relative;
        background: #f3f5f9
            url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAGCAYAAAD68A/GAAAAAXNSR0IArs4c6QAAADhlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAAqACAAQAAAABAAAACqADAAQAAAABAAAABgAAAADdQHfOAAAAnklEQVQIHWOU1nYwYGBkmM/wnyHx6dUDFxiQAFiOiWE9wz+GQCZGRsYJQGzAyMSwHywBVQhig8QYGRgVwGoEFBwEuHkYDzAyMugzMPz/8P8fgyNILUgRkBT4/5/h4tcv/x0YoQYwSOs6LADqjgcphogBFTH8X/j08oEEEJ8ZIsjA8PnVgw284gqKQMUWQJM4kBXB1KDQIJNBGEUQyAEAhx4uhuoIJfsAAAAASUVORK5CYII=)
            no-repeat 95% !important;
        i {
            padding: 0.5rem;
            color: red;
            position: absolute;
            right: 40px * $width;
            cursor: pointer;
        }
    }
    label.disabled {
        width: 100%;
        height: 60px;
        cursor: not-allowed;
        border-radius: 1rem;
        padding: 01rem;
        overflow: hidden;
        white-space: nowrap;
        display: flex;
        align-items: center;
        margin-bottom: 0;
        font-family: DiodrumArabic !important;
        border: 1px solid #ccc;
        color: #7c8591 !important;
        font-weight: 900;
        position: relative;
        background: #f3f5f9
            url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAGCAYAAAD68A/GAAAAAXNSR0IArs4c6QAAADhlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAAqACAAQAAAABAAAACqADAAQAAAABAAAABgAAAADdQHfOAAAAnklEQVQIHWOU1nYwYGBkmM/wnyHx6dUDFxiQAFiOiWE9wz+GQCZGRsYJQGzAyMSwHywBVQhig8QYGRgVwGoEFBwEuHkYDzAyMugzMPz/8P8fgyNILUgRkBT4/5/h4tcv/x0YoQYwSOs6LADqjgcphogBFTH8X/j08oEEEJ8ZIsjA8PnVgw284gqKQMUWQJM4kBXB1KDQIJNBGEUQyAEAhx4uhuoIJfsAAAAASUVORK5CYII=)
            no-repeat 95% !important;
        i {
            padding: 0.5rem;
            color: red;
            position: absolute;
            right: 40px * $width;
            cursor: pointer;
        }
    }
    .drop-down-list {
        list-style: none;
        width: 100%;
        padding: 0;
        position: absolute;
        background-color: white;
        z-index: 110;
        border: 1px solid #ccc;
        overflow: auto;
        max-height: 20rem;
        font-family: DiodrumArabic;
        li {
            width: 100%;
            padding: .5rem 1rem;
            cursor: pointer;
            color: #3c4858;
            border: 1px dotted #CCC;
        }
        li:nth-child(n + 2) {
            line-height: 2.5rem;
            margin-bottom: 0.2rem;
        }
        li:nth-child(n + 2):hover {
            background-color: #779ae6;
            color: white;
        }
        li.choosed {
            background-color: #1458e9;
            color: white;
        }
        li:first-child {
            display: flex;
            align-items: center;
            input {
                width: 100%;
                border: 1px solid #ccc;
                height: 54px;
                border-radius: 0.3rem;
                line-height: 100%;
            }
            .insert {
                width: 2.5rem;
                height: 2.5rem;
                background-color: rgb(20, 88, 233);
                border: none;
                margin-left: 0.5rem !important;
                padding: 0.5rem;
                border-radius: 1rem;
                img {
                    width: 100%;
                    margin: 0;
                }
            }
        }
    }
}
