.channel-card-container {
    cursor: pointer;

    .channel-card {
        text-align: center;
        aspect-ratio: 4/3;
        background-size: 50%;
        background-repeat: no-repeat;
        background-position: center;
        background-color: #f3f5fa;
        border-radius: 1rem;
        width: 100%;
    }
    p {
        font-family: DiodrumArabic;
        text-align: center;
    }
    &.active {
        color: #1458e9;
        .channel-card {
            border: 1px solid #1458e9;
        }
    }
}
