$width: 100vw / 1520px;
.container-fluid {
    display: flex;
    flex-direction: column;
}
.login {
    width: 100%;
    height: 100vh;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 15px;

    .wrapper {
        width: 960px * $width;
        background: #fff;
        border-radius: 1rem;
        overflow: hidden;
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        /* padding: 100px 100px 33px 95px; */
        border: 1px solid #d8dee7;
    }
    .login-pic {
        will-change: transform;
        transform: perspective(300px) rotateX(0deg) rotateY(0deg);
        text-align: center;
        padding: 3rem;
        background-color: #f3f5fa;
        width: 490px * $width;
    }
    input,
    button {
        border-radius: 2rem !important;
        height: 60px;
    }
    input {
        background-color: #f3f5f9;
    }
}
