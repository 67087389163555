.upload-container {
    height: 10rem;
    border-radius: 1rem;
    background-color: #f3f5f9;
    cursor: pointer;
    align-items: center;
    display: flex;
    text-align: center;
}
.upload-container label {
    /* Style as you please, it will become the visible UI component. */
    display: block;
    margin: 0 auto;
    cursor: inherit;
}

.upload-container .upload-photo {
    opacity: 0;
    position: absolute;
    z-index: -1;
}
