.rtl .user-badge-container {
    margin-right: 21.7%;
}
.ltr .user-badge-container {
    margin-left: 21.7%;
}
.user-badge-container .user-activity {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 60px;
    height: 60px;
    border-radius: 20px;
    box-shadow: 0 8px 12px rgba(229, 233, 242, 0.5);
    background: #fff url("../assets/img/user-icon.png") no-repeat center center;
}
.user-activity:after {
    content: "";
    width: 20px;
    height: 20px;
    border: 3px solid #fff;
    border-radius: 1rem;
    background: #2dca73;
    top: 30px;
    position: relative;
    left: 33%;
}
@media only screen and (min-width: 1400px) {
    .navbar-nav.ml-auto {
        right: 22rem;
        position: absolute;
        top: 1rem;
    }
}
@media only screen and (max-width: 1400px) {
    .navbar-nav.ml-auto {
        left: 2rem;
        position: absolute;
        top: 1rem;
    }
}
/* .user-badge-container .user-dropdown {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 30px;
    height: 30px;
    border-radius: 20px;
    box-shadow: 0 5px 15px rgba(229, 233, 242, 0.7);
    background: #fff url("../../assets/img/dropdown-icon.png") no-repeat center center;
} */
