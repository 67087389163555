.side-nav .collapsible > li a.collapsible-header.active {
    background: none;
}
.rtl .md-form label {
    left: unset !important;
    right: 0;
}
.rtl .right-margin {
    margin-right: 350px;
}
.rtl .right-dir {
    direction: rtl;
}
.rtl .right-txt {
    text-align: right;
}
.rtl .right-align {
    text-align: right;
    padding-right: 30px !important;
    padding-left: 0px !important;
}
.rtl .Toastify__toast-body {
    font-family: "IRANSansMobile(FaNum)", DiodrumArabic;
    text-align: right;
    direction: rtl;
}
.rtl .Ripple-parent.collapsible-header.d-flex.flex-row-reverse.justify-content-around.align-items-center {
    padding: 0 !important;
}
.rtl .sv-normal {
    width: 100%;
    direction: rtl;
    text-align: center;
    display: flex;
    align-items: center;
}
.rtl .side-nav .fa-angle-down.rotate-icon {
    right: unset;
    left: 0;
}
.rtl .collapsible.collapsible-accordion .collapsible-header i:not(.rotate-icon) {
    margin-left: 1rem !important;
    margin-right: 0 !important;
}

.rtl .collapsible.collapsible-accordion .collapsible-header i {
    margin-left: 1.25rem;
    margin-right: 0;
}

.rtl .Ripple-parent.arrow-r {
    width: 100%;
}

.rtl .md-form label {
    right: 0;
    left: unset;
}
.rtl .collapsible.collapsible-accordion {
    direction: rtl;
    text-align: right;
}
.rtl .side-nav .fa-angle-down.rotate-icon {
    right: unset;
    left: 0;
}
.rtl .select-wrapper span.caret {
    left: 0 !important;
    right: unset !important;
}
.rtl .select-wrapper.mdb-select .select-dropdown,
.rtl .dropdown-content.select-dropdown li span {
    text-align: right;
}
.rtl .select-wrapper.mdb-select .select-dropdown,
.rtl .dropdown-content.select-dropdown {
    padding: 0;
}
.rtl .dropdown-content {
    max-height: 200px !important;
}
@media (min-width: 1000px) {
    .list-unstyled {
        padding-right: 45px;
        padding-left: 45px !important;
    }
}
@media (max-width: 1000px) {
    .list-unstyled {
        padding-right: 15px;
        padding-left: 15px !important;
    }
}
.collapsible-header.active {
    background-color: #fff;
}
.side-nav.wide .collapsible a {
    padding-left: 0 !important;
}
.side-nav .collapsible > li a.collapsible-header {
    font-size: 18px;
}
.Ripple-parent.collapsible-header {
    margin-top: 20px;
    height: 100%;
    width: 100%;
    border-radius: 14px;
}
.collapsible.collapsible-accordion li {
    height: 64px;
}
.side-nav .collapsible a {
    height: unset;
}
.sv-normal img.icon {
    top: 25px;
    height: 24px;
    width: 24px;
}
.rtl .sv-normal img.caret {
    width: 8px;
    left: 20.5px;
    position: absolute;
    top: 1.6rem;
}
.ltr .sv-normal img.caret {
    width: 8px;
    right: 20.5px;
    position: absolute;
    top: 1.6rem;
}
.sv-normal {
    height: 64px;
    width: 100%;
    text-align: center;
    display: flex;
    align-items: center;
}
.sv-normal div {
    padding: 20px;
}
.Ripple-parent.collapsible-header.active {
    background-color: white !important;
    border-radius: 12px !important;
}
.report-table th {
    border-bottom-color: #3c4858 !important;
    border-bottom-width: 1px !important;
}
.report-table td {
    border-bottom-color: #c0ccda !important;
    border-bottom-width: 1px !important;
}
