
$width: 100vw / 1520px;

.archive{
    .channel-row{
        width: 1040px * $width;
        .channel-img-container{
            width: 157px * $width;
            height: 134px;
        }
        height: 180px;;
    }
}